<template>
  <div>
    <v-layout>
      <v-btn
        icon
        class="primary--text mt-1 mr-2"
        :to="`/${projectId}/${namespaceId}/n/applications`"
        router
        exact
      >
        <v-icon>keyboard_backspace</v-icon>
      </v-btn>
      <page-title>New Application</page-title>
    </v-layout>

    <v-divider class="mt-2 mb-2"></v-divider>

    <v-layout>
      <page-subtitle
        >Specify your new application configuration here. Learn more about app
        specification in the official
        <a
          href="https://docs.synpse.net/applications/app-specification"
          class="ml-1"
          target="_blank"
          rel="noopener"
          >documentation</a
        >.
      </page-subtitle>
      <v-spacer></v-spacer>
      <v-tooltip left>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            icon
            v-on="tooltip"
            href="https://docs.synpse.net/applications/app-specification"
            target="_blank"
            rel="noopener"
          >
            <v-icon color="grey">help</v-icon>
          </v-btn>
        </template>
        <span>
          Find out more about the application spec format here:
          https://docs.synpse.net/applications/app-specification
        </span>
      </v-tooltip>
    </v-layout>

    <v-layout class="mb-3" wrap>
      <v-flex lg8 md12 sm12 pa-2>
        <v-tabs v-model="tab" centered>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">FORM</v-tab>
          <v-tab href="#tab-2">YAML</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card flat>
              <v-card-text>
                <!-- Application name -->
                <v-row align="center">
                  <v-col class="d-flex" md="8" sm="12" xs="12">
                    <v-text-field
                      v-model="newApplication.name"
                      :rules="nameRules"
                      :counter="32"
                      label="Application name"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!-- Scheduling -->
                <v-row align="center" class="mt-2">
                  <v-col class="d-flex" md="8" sm="12" xs="12">
                    <v-select
                      :items="availableSchedulingTypes"
                      label="Scheduling"
                      v-model="newApplication.scheduling.type"
                      item-text="name"
                      item-value="type"
                      outlined
                    ></v-select>
                  </v-col>
                </v-row>
                <v-divider class="mb-5"></v-divider>
                <v-row align="center">
                  <v-col class="d-flex" md="8" sm="12" xs="12">
                    <p>Configure Docker Containers</p>                    
                  </v-col>
                </v-row>
                <!-- Containers -->
                <template v-for="(container, i) in newApplication.containers">
                  <div :key="`container-${i}`">
                    <v-card outlined class="mb-2">
                      <v-card-text>
                        <!-- <v-divider class="mb-5 mt-5" v-if="i > 0"></v-divider> -->
                        <!-- Container name -->
                        <v-row align="center">
                          <v-col class="d-flex" md="8" sm="12" xs="12">
                            <v-text-field
                              v-model="container.name"
                              required
                              :rules="containerNameRules"
                              label="Container name"
                              hint="Containers can connect to each other using container names"
                            ></v-text-field>
                          </v-col>
                          <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-btn
                                class="ml-2"
                                small
                                icon
                                v-on="tooltip"
                                :disabled="newApplication.containers.length < 2"
                                @click="deleteContainer(i)"
                              >
                                <v-icon color="primary">mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Remove container from the app</span>
                          </v-tooltip>
                        </v-row>
                        <!-- Docker image -->
                        <v-row align="center">
                          <v-col class="d-flex" md="8" sm="12" xs="12">
                            <v-text-field
                              v-model="container.image"
                              required
                              label="Image name"
                              hint="Your DockerHub or any other registry image name"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <!-- Command -->
                        <v-row align="center">
                          <v-col class="d-flex" md="8" sm="12" xs="12">
                            <v-text-field
                              v-model="container.command"
                              required
                              label="Command"
                              hint="For example 'python run.py'"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <!-- Network mode -->
                        <v-row align="center">
                          <v-col class="d-flex" md="8" sm="12" xs="12">
                            <v-select
                              :items="availableNetworkModes"
                              label="Network mode"
                              v-model="container.networkMode"
                              item-text="name"
                              item-value="type"
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                        <!-- Ports in bridge mode -->
                        <div v-if="container.networkMode === 'default'">
                          <v-row align="center">
                            <v-col class="d-flex" md="8" sm="12" xs="12">
                              <p>
                                Expose one or more ports to reach your
                                application.
                              </p>
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                class="primary--text"
                                :disabled="loading"
                                :loading="loading"
                                @click="addPortMapping(container)"
                              >
                                <!-- <v-icon class="mr-2">save</v-icon> -->
                                Publish Ports
                              </v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="(port, i) in container.ports">
                            <v-row :key="`${container.name}-port-${i}`">
                              <v-col cols="" sm="3">
                                <v-text-field
                                  v-model="port.host"
                                  :rules="portRules"
                                  label="Host port"
                                  outlined
                                ></v-text-field>
                              </v-col>

                              <v-col cols="" sm="4">
                                <v-text-field
                                  v-model="port.container"
                                  :rules="portRules"
                                  label="Container port"
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col sm="1">
                                <div class="text-center">
                                  <v-btn
                                    fab
                                    icon
                                    @click="deletePortMapping(container, i)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                        <!-- Volumes -->
                        <div class="mt-4">
                          <v-row align="center">
                            <v-col class="d-flex" md="8" sm="12" xs="12">
                              <p>
                                Mount volumes to your container from the host
                                server to provide persistence between container
                                restarts.
                              </p>
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                class="primary--text"
                                :disabled="loading"
                                :loading="loading"
                                @click="addVolumeMapping(container)"
                              >
                                Mount Volume
                              </v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="(volume, i) in container.volumes">
                            <v-row :key="`${container.name}-volume-${i}`">
                              <v-col cols="" sm="3">
                                <v-text-field
                                  v-model="volume.host"
                                  :rules="volumeRules"
                                  label="Host filepath"
                                  outlined
                                ></v-text-field>
                              </v-col>

                              <v-col cols="" sm="4">
                                <v-text-field
                                  v-model="volume.container"
                                  :rules="volumeRules"
                                  label="Path in the container"
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col sm="1">
                                <div class="text-center">
                                  <v-btn
                                    fab
                                    icon
                                    @click="deleteVolumeMapping(container, i)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                        <!-- Environment Variables -->
                        <div class="mt-4">
                          <v-row align="center">
                            <v-col class="d-flex" md="8" sm="12" xs="12">
                              <p>
                                Environment variables provide a standard,
                                cloud-native way of configuring your
                                applications.
                              </p>
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                class="primary--text"
                                :disabled="loading"
                                :loading="loading"
                                @click="addEnvVar(container)"
                              >
                                Configure Env
                              </v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="(env, i) in container.env">
                            <v-row :key="`${container.name}-env-${i}`">
                              <v-col cols="" sm="3">
                                <v-text-field
                                  v-model="env.name"
                                  :rules="envRules"
                                  label="Env variable key"
                                  outlined
                                ></v-text-field>
                              </v-col>

                              <v-col cols="" sm="4">
                                <v-text-field
                                  v-model="env.value"
                                  :rules="envRules"
                                  label="Env variable value"
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col sm="1">
                                <div class="text-center">
                                  <v-btn
                                    fab
                                    icon
                                    @click="deleteEnvVar(container, i)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                        <!-- Secrets -->
                        <div class="mt-4">
                          <v-row align="center">
                            <v-col class="d-flex" md="8" sm="12" xs="12">
                              <p>
                                Secrets can be used to inject sensitive
                                configuration into your application containers
                                as environment variables.
                              </p>
                              <v-spacer></v-spacer>
                              <v-btn
                                outlined
                                class="primary--text"
                                :disabled="loading"
                                :loading="loading"
                                @click="addEnvSecret(container)"
                              >
                               Use Secret Env
                              </v-btn>
                            </v-col>
                          </v-row>
                          <template v-for="(secret, i) in container.secrets">
                            <v-row :key="`${container.name}-secret-${i}`">
                              <v-col cols="" sm="3">
                                <v-text-field
                                  v-model="secret.name"
                                  :rules="envRules"
                                  label="Env variable key"
                                  outlined
                                ></v-text-field>
                              </v-col>

                              <v-col cols="" sm="4">
                                <v-select
                                  :items="secrets"
                                  label="Secret Value From"
                                  v-model="secret.fromSecret"
                                  item-text="name"
                                  item-value="name"
                                  outlined
                                ></v-select>
                              </v-col>
                              <v-col sm="1">
                                <div class="text-center">
                                  <v-btn
                                    fab
                                    icon
                                    @click="deleteEnvSecret(container, i)"
                                  >
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </template>
                        </div>
                      </v-card-text>
                    </v-card>
                  </div>
                </template>
              </v-card-text>
              <v-card-actions v-if="error || yamlError">
                <v-alert v-if="error" dense outlined type="error">
                  {{ error }}
                </v-alert>
                <v-alert v-if="yamlError" dense outlined type="error">
                  {{ yamlError }}
                </v-alert>
              </v-card-actions>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text class="primary--text mr-3" @click="addContainer()">
                  <v-icon class="mr-2">add_box</v-icon>
                  Add Container
                </v-btn>

                <v-btn
                  text
                  class="primary--text"
                  :disabled="loading"
                  :loading="loading"
                  @click="createApplication()"
                >
                  <v-icon class="mr-2">save</v-icon>
                  Deploy
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat >
              <v-card-text> Specify your application yaml below: </v-card-text>
              <v-card-text>
                <codemirror
                  v-model="applicationYaml"
                  :options="cmOptions"
                ></codemirror>
              </v-card-text>
              <v-card-actions>
                <v-alert v-if="error" dense outlined type="error">
                  {{ error }}
                </v-alert>
                <v-alert v-if="yamlError" dense outlined type="error">
                  {{ yamlError }}
                </v-alert>
                <v-spacer></v-spacer>

                <v-btn
                  text
                  class="primary--text"
                  :disabled="loading"
                  :loading="loading"
                  @click="createApplicationFromYaml()"
                >
                  <v-icon class="mr-2">save</v-icon>
                  Deploy
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-flex>
      <v-flex lg4 md12 sm12 pa-2>
        <v-card flat>
          <v-card-title>
            Namespace Secrets
            <v-tooltip top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  class="ml-2"
                  x-small
                  icon
                  v-on="tooltip"
                  href="https://docs.synpse.net/applications/secrets"
                  target="_blank"
                  rel="noopener"
                >
                  <v-icon color="grey">help</v-icon>
                </v-btn>
              </template>
              <span>
                Secrets can be stored separately from your application spec,
                improving security.
              </span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <secret-create></secret-create>
          </v-card-title>
          <v-card-text>
            <p v-if="secrets.length == 0">
              Secrets enable your applications to receive sensitive data (for
              example cloud credentials, admin passwords, etc.) without adding
              them to the app spec directly.
            </p>
            <v-list v-else subheader two-line>
              <v-list-item v-for="(item, key) in secrets" :key="key">
                <v-list-item-avatar>
                  <v-btn icon @click="copySecretToClipboard(item)">
                    <v-icon color="">mdi-content-copy</v-icon>
                  </v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.name}`"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    Created {{ item.createdAt | ago }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<style >
.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}
</style>

<script>
import PageSubtitle from '../PageSubtitle';
import PageTitle from '../PageTitle';
import SecretCreate from '../secrets/SecretCreate';

import YAML from 'yaml';

// Editor
import { codemirror } from 'vue-codemirror';

// require styles
import 'codemirror/lib/codemirror.css';

// language js
import 'codemirror/mode/yaml/yaml.js'

// theme css
import 'codemirror/theme/base16-dark.css';

export default {
  components: {
    PageSubtitle,
    PageTitle,
    SecretCreate,
    codemirror
  },
  data() {
    return {
      tab: 'tab-2',
      cmOptions: {
        // codemirror options
        tabSize: 4,
        mode: 'text/x-yaml',
        theme: 'base16-dark',
        lineNumbers: true,
        line: true
      },
      availableSchedulingTypes: [
        {
          name: 'All project devices',
          type: 'AllDevices'
        },
        {
          name: 'Do not schedule anywhere',
          type: 'NoDevices'
        },
        {
          name: 'Conditional based on labels',
          type: 'Conditional'
        }
      ],
      availableNetworkModes: [
        {
          name: 'Default',
          type: 'default'
        },
        {
          name: 'Host network (all application ports exposed)',
          type: 'host'
        },
      ],
      newApplication: {
        name: '',
        description: '',
        scheduling: {
          type: 'AllDevices',
          selector: {}
        },
        containers: [
          {
            name: '',
            image: '',
            command: '',
            networkMode: 'default',
            ports: [],
            volumes: [],
            env: [],
            secrets: []
          }
        ]
      },
      newDefaultContainer: {
        name: '',
        image: '',
        command: '',
        networkMode: 'default',
        ports: [],
        volumes: [],
        env: [],
        secrets: []
      },
      nameRules: [
        v => !!v || 'Name is required',
        v => v.length <= 10 || 'Name must be less than 32 characters',
        v => v.length >= 1 || 'Name must be at least 1 character',
      ],
      containerNameRules: [
        v => !!v || 'Container name is required',
        v => v.length <= 60 || 'Container name must be less than 60 characters',
        v => v.length >= 1 || 'Container name must be at least 1 character',
      ],
      portRules: [
        v => !!v || 'Port is required',
        v => v <= 65535 || 'Port numbet must be lower than 65535',
        v => v > 0 || 'Port number must be higher than 0',
      ],
      volumeRules: [
        v => !!v || 'Volume path',
        v => v.length <= 300 || 'Volume path must be shorter than 300 characters',
        v => v.length >= 1 || 'Volume path must be at least 1 character',
      ],
      envRules: [
        v => !!v || 'Env var is required',
        v => v.length <= 250 || 'Env var must be less than 250 characters',
      ],
      // creating an app via raw yaml 
      applicationYaml: '',
      yamlError: null,
      fileUrl: this.$route.query.fileUrl
    }
  },

  computed: {
    projectId() {
      return this.$route.params.project
    },
    namespaceId() {
      return this.$route.params.namespace
    },
    secrets() {
      return this.$store.state.secret.secrets
    },
    // Selected secret
    secret() {
      return this.$store.state.secret.secret
    },
    loading() {
      return this.$store.state.application.loading
    },
    error() {
      return this.$store.state.application.error
    },
    createdApplication() {
      return this.$store.state.application.createdApplication
    },
    theme() {
      return 'vs-dark'
    },
    deployTemplate() {
      return this.$store.state.application.deployTemplate 
    },    
    defaultYaml() {
      const defaultNewAppYaml = `name: hello-synpse # <- application name
scheduling:
  type: AllDevices # <- deploys on all project devices
  selectors: {}
spec:
  containers:
    - name: hello
      image: quay.io/synpse/hello-synpse-go:latest # <- Docker container image
      ports:
        - 8080:8080 # Specify host_port:container_port to expose your application
      env:
        - name: ENV_VARIABLE_NAME
          value: environment_variable_value
      volumes:
        - /data:/data # Specify host_dir:container_dir to mount for persistence
      `

      return defaultNewAppYaml
    }
  },

  mounted() {
    this.refresh()
    this.initializeTemplate()
  },

  methods: {
    refresh() {
      let q = {
        projectId: this.projectId,
        namespaceId: this.namespaceId,
        applicationId: this.applicationId
      }
      // Loading secrets
      this.$store.dispatch('ListSecrets', q)
    },
    async initializeTemplate() {
      if (this.fileUrl) {
        // Loading the fileUrl if given. We should already have this from the previous page
        // but we need to reload because sometimes if the user refreshes the page here, we would
        // lose it.
        await this.$store.dispatch('GetApplicationDeploymentTemplate', {
          fileUrl: this.fileUrl,
        })
        this.applicationYaml = this.$store.state.application.deployTemplate 
      } else {
        this.applicationYaml = this.defaultYaml
      }   
    },
    copySecretToClipboard(secret) {
      this.$store.dispatch('Notify', `Copied!`)
      this.$clipboard(secret.name)
    },
    createApplication() {
      // Application containers
      let containers = []

      for (const containerConfig of this.newApplication.containers) {
        // Preparing JSON configuration
        let container = {
          name: containerConfig.name,
          image: containerConfig.image,
          command: containerConfig.command.toString(),
          networkMode: containerConfig.networkMode,
          ports: [],
          volumes: [],
          env: []
        }
        for (const portConfig of containerConfig.ports) {
          container.ports.push(`${portConfig.host}:${portConfig.container}`)
        }
        for (const volumeConfig of containerConfig.volumes) {
          container.volumes.push(`${volumeConfig.host}:${volumeConfig.container}`)
        }
        for (const envConfig of containerConfig.env) {
          container.env.push(envConfig)
        }
        // Adding secrets as env variables
        for (const envSecretConfig of containerConfig.secrets) {
          container.env.push(envSecretConfig)
        }

        // Adding configuration to the spec
        containers.push(container)
      }

      // Application spec
      let payload = {
        name: this.newApplication.name.toString(),
        description: this.newApplication.description.toString(),
        type: 'container',
        scheduling: {
          type: this.newApplication.scheduling.type,
          selector: this.newApplication.scheduling.selector,
        },
        spec: {
          containers: containers
        }
      }
      // Setting details
      payload.projectId = this.projectId
      payload.namespaceId = this.namespaceId

      this.$store.dispatch('CreateApplication', payload).then(() => {
        if (this.error != null) {
          // got error, don't reset form, let user
          // fix the mistake :S
          return
        }
        this.$store.dispatch('Notify', `Application '${payload.name}' created`)
        // Change route here to the newly created application
        this.$router.push(
          {
            name: 'applicationDetails',
            params: { project: this.projectId, namespace: this.namespaceId, application: this.createdApplication.id }
          })
      })
    },
    addPortMapping(container) {
      container.ports.push({
        'host': '',
        'container': ''
      })
    },
    deletePortMapping(container, index) {
      container.ports.splice(index, 1)
    },
    addVolumeMapping(container) {
      container.volumes.push({
        'host': '',
        'container': ''
      })
    },
    deleteVolumeMapping(container, index) {
      container.volumes.splice(index, 1)
    },
    addEnvVar(container) {
      container.env.push({
        'name': '',
        'value': ''
      })
    },
    deleteEnvVar(container, index) {
      container.env.splice(index, 1)
    },
    addEnvSecret(container) {
      container.secrets.push({
        'name': '',
        'fromSecret': ''
      })
    },
    deleteEnvSecret(container, index) {
      container.secrets.splice(index, 1)
    },
    addContainer() {
      let newContainer = Object.assign({}, this.newDefaultContainer)
      this.newApplication.containers.push(newContainer)
    },
    deleteContainer(index) {
      this.newApplication.containers.splice(index, 1)
    },
    createApplicationFromYaml() {
      this.yamlError = null
      let application = {}
      try {
        application = YAML.parse(this.applicationYaml)
      }
      catch (err) {
        this.yamlError = err
        return
      }

      // Setting details
      application.projectId = this.projectId
      application.namespaceId = this.namespaceId

      this.$store.dispatch('CreateApplication', application).then(() => {
        if (this.error === null) {
          // All good
          this.$store.dispatch('Notify', `Application '${application.name}' created`)
          // Change route here to the newly created application
          this.$router.push(
            {
              name: 'applicationDetails',
              params: { project: this.projectId, namespace: this.namespaceId, application: this.createdApplication.id }
            })

          return
        }
        // Showing error
      })
    }
  }
}
</script>